/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
		scrollTop: 0,
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $("[data-target='#navbarNav']").click(function(){
          var $this = $(this);
          if( $this.hasClass( "collapsed" ) ){
            $("body").addClass( "primary-navigation-opened" );
            $this.removeClass( "collapsed" );
          }else {
            $("body").removeClass( "primary-navigation-opened" );
            $this.addClass( "collapsed" );
          }
        });

        var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

        if ("IntersectionObserver" in window) {
          var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(video) {
              if (video.isIntersecting) {
                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                    videoSource.src = videoSource.dataset.src;
                  }
                }

                video.target.load();
                video.target.classList.remove("lazy");
                lazyVideoObserver.unobserve(video.target);

                if ($(window).width() <= 767) {
                  $(video.target).get(0).pause();
                  var inview = new Waypoint.Inview({
                    element: video.target,
                    enter: function(direction) {
                      $(video.target).css('opacity', '1');
                      $(video.target).get(0).play();
                    },
                    exited: function(direction) {
                      $(video.target).get(0).pause();
                    }
                  });
                } else {
                  $(video.target).on("mouseenter", function(e) {
                    $(e.target).css('opacity', '1');
                    $(e.target).get(0).play();
                  })
                  .on("mouseleave", function(e) {
                    $(e.target).get(0).pause();
                  });
                }
              }
            });
          });

          lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
          });
        }

        var sent = false;

        $('#contactForm').on('submit', function(e) {
          e.preventDefault();
          console.log("Submit contact form");
          if (sent === false) {
            sent = true;
            var theForm = $(this);
            var submitBtn = $('input[type="submit"]', theForm);
            $(submitBtn).attr('disabled', 'disabled').html("Sending...");
            $.post("/wp-admin/admin-post.php?action=contact_form", {
              data: JSON.stringify({
                name: $("input[name='name']", theForm).val(),
                email: $("input[name='email']", theForm).val(),
                message: $("textarea", theForm).val()
              })
            })
            .done(function(data) {
              console.log("Data Loaded: " + data);
              $(submitBtn).attr('value', $('html').attr('lang') === 'fr-FR' ? 'Message Envoyé': 'Message Sent')
                .prop('disabled', true);
              sent = false;
            });
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*if($(".hovervideo").length > 0){
          var hoverVideo = function(e) {
            $(".hovervideo", this).css('opacity', '1');
            $(".hovervideo", this).get(0).play();
          };

          var hideVideo = function(e) {
            $(".hovervideo", this).get(0).pause();
          };

          if($(window).width() <= 767){
            $(".worksamples .item .hovervideo").each(function(){
              $(this).css('opacity', '1');
              $(this).get(0).play();
            });
          }else{
            $(".worksamples .item").hover(hoverVideo, hideVideo);
          }
        }*/

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // console.log('Testing');
      }
    },
    'contact': {
      init: function () {
      },
      finalize: function () {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    'archive': function(){
      $( window ).scroll( function( e ){
        var height = $( "body" ).height();
        var scroll = $( this ).scrollTop() + height;
        var $btn = $( '.load-more' );
        var position = $btn.length > 0 ? $btn.offset().top : 0;
        var offset = 1000;
        if( position && ( scroll ) >= ( ( height / 3 ) * 2 ) && !$btn.hasClass( "loading" ) ){
          $btn.trigger( 'click' );
        }
      } );
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
